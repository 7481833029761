@font-face {
  font-family: the-seasons;
  src: url(../../../public/Fonts/Fontspring-DEMO-theseasons-bd.otf);
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");

.experience {
  display: flex;
  margin-bottom: 50px;
}

.experience img {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}

.experience p {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.experience h3 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 20px;
  font-weight: 800;
  margin: 0;
}

.experience_content {
  padding: 3% 10% 0 10%;
}

@media (max-width: 990px) {
  .experience {
    text-align: left;
  }
}

.exp_heading {
  display: flex;
  margin-bottom: 5px;
  align-items: start;
  align-content: start;
  justify-content: space-between;
}

h3.duration {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 15px;
  color: #7d7d7d;
}
