@font-face {
  font-family: the-seasons;
  src: url(../../../public/Fonts/Fontspring-DEMO-theseasons-bd.otf);
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
.title {
  font-size: 69px;
  font-family: the-seasons;
  margin-bottom: 10px;
  color: #fff;
}

.subtitle {
  font-size: 35px;
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 800;
  margin: 60px auto;
  text-align: center;
}
@media (max-width: 990px) {
  .main {
    text-align: center;
  }
}

.project_data {
  display: flex;
  margin-top: 20px;
  margin-bottom: 0;
  align-items: center;
}

.project .project_img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
}

.project_content {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  padding: 20px;
  width: 100%;
  justify-content: space-evenly;
}

.project_links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.project_links a {
  margin: 0 10px;
  font-size: 30px;
  color: #fff;
  height: 33px;
  width: 33px;
}

.project_links a img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.project_heading {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
