@font-face {
    font-family: the-seasons;
    src: url(../../../public/Fonts/Fontspring-DEMO-theseasons-bd.otf);
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
.title{
    font-size: 69px;
    font-family: the-seasons;
    margin-bottom: 10px;
}


.certificate{
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 80px;
}


.certificate a{
    color: #fff;
    letter-spacing: 2px;
    text-decoration: underline;
    text-shadow: #fff;
    font-weight: 800;
    margin-left: 15px;
    font-size: 15px;
}

.certificate img{
    width: 50px;
    height: 50px;
}
.certificates_content{
    padding: 3% 10%;
    
}