@font-face {
  font-family: the-seasons;
  src: url(../../../public/Fonts/Fontspring-DEMO-theseasons-bd.otf);
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");
p {
  font-size: 17px;
}
.main {
  background-color: #000;
  color: #fff;
  padding: 5% 15%;
  font-family: "Libre Franklin", sans-serif;
}
.content {
  display: flex;
  align-items: center;
}
.name {
  font-size: 69px;
  font-family: the-seasons;
  margin-bottom: 10px;
}
.mini-text {
  display: flex;
  align-items: center;
  padding-right: 80px;
  float: right;
  font-size: 15px;
}
.heading {
  border: 1px solid #fff;
  border-radius: 30px;
  width: 40%;
  text-align: center;
  padding: 15px 40px;
  margin-bottom: 30px;
}

@media (max-width: 990px) {
  .content {
    flex-direction: column-reverse;
    text-align: center;
  }
  .heading {
    text-align: center;
    margin: auto;
  }
  .img_parent {
    padding-left: 0px;
  }
  .mini-text {
    display: none;
  }
}

.rounded_image {
  margin: auto;
  border-radius: 70% 30%;
  transform: rotateZ(-5deg);
}
.img_parent {
  padding-left: 40px;
}

a {
  color: #fff;
}
