.contact_content{
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact_content p{
    margin-bottom: 0;
}
.contact_links{
    display: flex;
    align-items: start;
}

.contact_links a{
    margin-left: 30px;
    font-size: 20px;
    height: 30px;
    width: 30px;
}

.contact_links a img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
}


@media (max-width: 990px) {

    .contact_content{
        flex-direction: column;
        justify-content: center;
        margin-top: 100px;
    }

}